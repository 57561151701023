import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About the company`}</h2>
    <span style={{
      "fontSize": "x-small"
    }}> (really just about me I know it's shameless) </span>
    <blockquote>
      <p parentName="blockquote">{`The things here should be super meaningful, and I'm putting all my faith on future me figuring out exactly what to say.`}</p>
    </blockquote>
    <p>{`He's a smart guy he'll know what to do. Probably a longer text here most likely somehow referring to the meaningful things he came up with above probably about having a social impact and using your skills/opportunities to do good. Maybe something funny, even witty. But a warning he'll probably try to get you to check out his soundcloud though you can probably skip it and just say it was really dope but then he'll go see if there are any new listeners and get pretty upset that you lied to him but hey nothing personal just a promise you broke.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      