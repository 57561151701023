/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui"

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Box as="footer" variant="footer">
      <button
        sx={{ variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`, mb: 3 }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        <span style={{ textDecoration: 'underline' }}>{isDark ? `Why is this website so dark are you living in a basement?` : `What are you doing to my eyes this website is too bright are you wearing sunglasses indoors or something?`}</span>        
        {isDark ? ` Yes that is exactly right are you also a developer and want to join me here working in a cool software company? You'll do some good too!` : ` Yes that is exactly correct maybe you also need a new pair can I get you some great wooden ones? You'll do some good too!`}
      </button>
    </Box>
  )
}

export default Footer
